import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const SignInContent = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 400px;
  min-height: 650px;

  > button {
    flex: unset;
  }
`;

export const StyledSignInPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(--spacing-3xl);
  overflow: hidden;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 0;

    ${SignInContent} {
      padding: var(--spacing-xl);
    }
  }
`;

export const Anchor = styled.a`
  display: flex;
  flex-direction: column;
`;

export const Logomark = styled.img`
  height: 76px;
`;

export const Wordmark = styled(RMWordmark)`
  height: 25px;
  pointer-events: none;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);

  > div {
    background-color: var(--border-hairline);
    height: 1px;
    width: 100%;
  }
`;

export const HiddenView = styled.nav<{ hide: boolean }>`
  opacity: ${(props) => (props.hide ? 0 : 1)};
  pointer-events: ${(props) => (props.hide ? 'none' : 'auto')};
  width: fit-content;
  visibility: ${(props) => (props.hide ? 'hidden' : 'unset')};
`;

export const SignInLink = styled.div`
  padding-top: var(--spacing-xl);
  display: flex;
  justify-content: center;
`;

export const LoginLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
